import React from 'react';
import './Button.css';

const STYLES = ["btn--primary", "btn--outline"];
const SIZES = ['btn--medium', 'btn--small', 'btn--large'];
const COLORS = ['yellow', 'red', 'green', 'blue', 'orange']

export const Button = ({children, type, onClick, buttonStyle, buttonSize, btnColor}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    const checkButtonColor = COLORS.includes(btnColor) ? btnColor : COLORS[0];

    return (
        <button className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`} onClick={onClick} type={type}>
            {children}
        </button>
    )
};