import React  from "react";
import './ContactForm.css';
import { Button } from "../Button/Button";

function ContactForm() {
    return (
        <div className="form-div">
            <form 
                name="contact" 
                method="POST" 
                action="/home"
                netlify-honeypot="bot-field"
                data-netlify="true"
            >
                <input type="hidden" name="form-name" value="contact"/>
                <p class="hidden">
                    <label>
                        Don’t fill this out if you’re human: <input name="bot-field" />
                    </label>
                </p>
                <p>
                    <div><label className="form-label">Name:</label></div>
                    <input className="text-input" type="text" name="name" />
                </p>
                <p>
                <div><label className="form-label">Your Email:</label></div>
                    <input className="text-input" type="email" name="email" />
                </p>
                <p>
                    <div><label className="form-label">Message:</label></div>
                    <textarea className="text-input" name="message"></textarea>
                </p>
                <p>
                    <Button type="submit" btnColor={"yellow"}>Send</Button>
                </p>
            </form>
        </div>
    )
}

export default ContactForm