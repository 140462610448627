import "./Navbar.css"; 
import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import {Button} from '../Button/Button'


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        }
        else {
            setButton(true);
        }
    }

    useEffect(() => {
        showButton()
    }, [])

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo">
                        A. Wisbar <div className="title-icon"></div>
                    </Link>
                    
                    <div className="menu-icon" onClick={handleClick}>
                        <div className={click ? "icon x-menu" : "icon menu"}/>
                    </div>
                    
                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                                    Home
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="/portfolio" className="nav-links" onClick={closeMobileMenu}>
                                    Portfolio
                                </Link>
                            </li> */}
                            {!button &&
                            <li className="nav-item" >
                                <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>Contact</Link>
                            </li>}
                        </ul>
                        <Link to="/contact">{button && <Button onClick={() => {}}>Contact</Button>}</Link>
                </div>
            </nav>
        </>
    )
}

export default Navbar