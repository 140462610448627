import React from "react"
import "../../App.js"
import BodyHeader from "../BodyHeader/BodyHeader.js"
//import LanguageBanner from "../LanguageBanner/LanguageBanner.js"
//import ResumeSection from "../ResumeSection/ResumeSection.js"

function Home() {
    return (
        <>
            <BodyHeader />
            {/* <ResumeSection/> */}
            {/* <LanguageBanner /> */}
        </>
    )
}

export default Home