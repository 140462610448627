import React from "react"
import "../../App.js"
import ContactForm from "../ContactForm/ContactForm.js"

function Contact() {
    return (
        <>
            <ContactForm/>
        </>
    )
}

export default Contact