import {React, useState} from "react";
import {Button} from "../Button/Button"
import {Link} from 'react-router-dom'
import './BodyHeader.css';

function BodyHeader() {

    const [buttonSize, setButtonSize] = useState(window.innerWidth <= 960);

    const changeButtonSize = () => {
        setButtonSize(window.innerWidth <= 960)
    }

    window.addEventListener("resize", changeButtonSize);
    return (
        <div className="body-header-container">
            <div className="bio-container">
                <img src="/andrewwisbar-logo.png" className="logo-image" alt="Andrew Wisbar"></img>
                <h1 className="bio-header">{"/* Software Developer */"}</h1>
                <p className="bio-text">
                    Hello! My name is Andrew Wisbar, and I'm currently enrolled
                    as an undergraduate student at Northern Illinois University, 
                    where I'm studying Computer Science and working as a 
                    Research Assistant.</p>
                <p className="bio-text">
                    I'm expecting to graduate in May 2023, and currently seeking oppurtunities in Software Development, 
                    preferably in distributed application development, but I'm
                    open to a wide range of positions.
                </p>
                <p className="bio-text">
                    I'm proficient with many programming languages 
                    and software tools including (but not limited to):
                </p>
                <ul>
                    <li className="bio-text">C and C++</li>
                    <li className="bio-text">C# and Java</li>
                    <li className="bio-text">Javascript, HTML, CSS and React</li>
                    <li className="bio-text">Python</li>
                </ul>
                <div className="button-container">
                    <Link className="button-link" to="/contact">{<Button buttonSize={(buttonSize) ? "btn--small" : "btn--medium"} btnColor={"blue"} onClick={() => {}}>Contact</Button>}</Link>
                    <Link className="button-link" to="/awisbar-resume.pdf"  target="_blank" download><Button btnColor={"yellow"} buttonSize={(buttonSize) ? "btn--small" : "btn--medium"}>Résumé</Button></Link>
                    {/* <Link className="button-link" to="/portfolio">{<Button btnColor={"blue"} onClick={() => {}} buttonSize={(buttonSize) ? "btn--small" : "btn--medium"}>Portfolio</Button>}</Link> */}
                </div>
            </div>
            <img className="personal-photo" src="/andrew-wisbar.png" alt="Andrew Wisbar looking extremely professional and impressive"></img>
        </div>
    )
}

export default BodyHeader